<template>
    <div>
        <v-toolbar elevation="1" dark color="primary">
                <v-toolbar-title>
                    Tiempo de espera URGENCIAS
                </v-toolbar-title>
        </v-toolbar>
        <v-sheet>
            <v-row no-gutters> 
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-date-picker
                            v-model="fechas"
                            range
                            :max="estaFecha"
                            full-width
                            no-title
                            locale="es-mx"
                            scrollable
                        ></v-date-picker>
                        {{fechas}}
                    </v-col>
                    <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                        Formulario
                    </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
export default {
    data: () => ({
        fechas:[],
    }),

    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        }
    }
}
</script>

<style>

</style>